import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import './i18n/config';
import './index.css';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
