import axios from 'axios';
import api from './config/axiosInstance';
import baseURL from './config/baseURL';

const applicationAPIs = {
	async uploadFile(file) {
		try {
			const response = await api.post('/uploads', file);
			return response.data;
		} catch (error) {
			return console.log(error);
		}
	},

	async submitApplication(file) {
		try {
			const response = await axios.post(`${baseURL}/api/en/v1/application`, file);
			return response.data;
		} catch (error) {
			return error;
		}
	},

	async applicationOtpValidationAPI(data) {
		try {
			const response = await axios.post(`${baseURL}/api/en/v1/application/validate`, data);
			return response.data;
		} catch (error) {
			console.error('Error in setNewPasswordAPI:', error);
			throw error;
		}
	},
};

export const { uploadFile, submitApplication, applicationOtpValidationAPI } = applicationAPIs;

export default applicationAPIs;
