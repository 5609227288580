import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const setAccessToken = (token, opts = {}) => cookies.set('access-token', token, opts);
export const setRefreshToken = (token, opts = {}) => cookies.set('refresh-token', token, opts);
export const getAccessToken = () => cookies.get('access-token');
export const getRefreshToken = () => cookies.get('refresh-token');
export const removeAccessToken = () => cookies.remove('access-token', { path: '/' });
export const removeRefreshToken = () => cookies.remove('refresh-token', { path: '/' });

// Randomly set and get cookie items
export const setCookieItem = (name = '', value = '', opts = { path: '/' }) =>
	cookies.set(name, value, opts);
export const getCookieItem = (name = '') => cookies.get(name);
export const removeCookieItem = (name) => cookies.remove(name, { path: '/' });

export default cookies;
