import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { languages } from './constants/languages';
import { AuthProvider } from './contexts/AuthContext';
import LanguageProvider from './contexts/LanguageContext';
import { UserRoleProvider } from './contexts/UserRoleContext';
import BaseLayout from './layout/BaseLayout';
import { initializeTagManager } from './utils/gtm';

const queryClient = new QueryClient();

const App = () => {
	const { i18n } = useTranslation();

	useEffect(() => {
		initializeTagManager();
	}, []);

	useEffect(() => {
		if (localStorage !== undefined) {
			const language = localStorage.getItem('language');

			if (language) {
				i18n?.changeLanguage(JSON.parse(language)?.value);
			} else {
				i18n?.changeLanguage(languages[0]?.value);
				localStorage.setItem('language', JSON.stringify(languages[0]));
			}
		}
	}, [i18n]);

	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<HelmetProvider>
					<LanguageProvider>
						<Toaster
							position="bottom-center"
							reverseOrder={false}
							gutter={8}
							containerStyle={{}}
							toastOptions={{
								className: '',
								duration: 4000,
								style: {
									borderRadius: '10px',
									background: '#333',
									color: '#fff',
								},
							}}
						/>
						<AuthProvider>
							<UserRoleProvider>
								<BaseLayout />
								<SpeedInsights />
							</UserRoleProvider>
						</AuthProvider>
					</LanguageProvider>
				</HelmetProvider>
			</QueryClientProvider>
		</BrowserRouter>
	);
};

export default App;
