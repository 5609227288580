/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getCompanyProfileAPI } from '../../apis/companyAPIs';
import ArrowDown from '../../assets/svgs/ArrowDown';
import LogoutSVG from '../../assets/svgs/LogoutSVG';
import NabCompanySVG from '../../assets/svgs/NabCompanySVG';
import { languages } from '../../constants/languages';
import useCurrentUserRole from '../../hooks/useCurrentUserRole';
import useLanguage from '../../hooks/useLanguage';
import useOutClick from '../../hooks/useOutClick';
import { setLanguage as setLanguageAction } from '../../reducers/languageReducer';
import { navbarMenuItems } from '../../routes/navbar-routes';
import { clearStorageAndRedirect } from '../../utils/clearStorageAndRedirect';

const NavBarCompanyDropdown = () => {
	const navDropDownRaf = useRef();
	const { i18n, t } = useTranslation();
	const { dispatch } = useLanguage();
	const [toggleModal, setToggleModal] = useState(false);
	const currentLan = localStorage.getItem('language');
	const [languageModal, setLanguageModal] = useState(false);

	const { state: currentUserRole } = useCurrentUserRole();

	useOutClick(navDropDownRaf, () => {
		setToggleModal(false);
	});

	const handleLogOut = () => {
		clearStorageAndRedirect('/');
	};

	const { data: profileData } = useQuery({
		queryKey: ['profile'],
		queryFn: () => getCompanyProfileAPI(),
	});

	if (profileData) {
		localStorage.setItem('company-profile-ID', profileData?.data?.id);
	}

	const handleLanguageChange = (txt) => {
		dispatch(setLanguageAction(txt));
		const filter = languages.filter((item) => item.label === txt);
		localStorage.setItem('language', JSON.stringify(filter[0]));
		i18n?.changeLanguage(txt === 'English' ? 'en' : 'bn');
		setLanguageModal(false);
		setToggleModal(false);
	};

	const handleToggleOutClick = (txt) => {
		if (txt !== 'Language') {
			setToggleModal(false);
		}
	};

	return (
		<div ref={navDropDownRaf} className="relative w-fit max-w-[319px] sm:min-w-[260px]">
			{/* Modal Button */}
			<button
				type="button"
				onClick={() => {
					setToggleModal((oldState) => !oldState);
				}}
				className="flex w-full items-center justify-between bg-white font-semibold sm:gap-3"
			>
				<div className="flex items-center gap-3">
					{profileData?.data?.logo ? (
						<img
							src={profileData?.data?.logo}
							alt="Company logo"
							className="me-2 h-[40px] w-[40px] rounded-full bg-white sm:h-[50px] sm:w-[50px]"
						/>
					) : (
						<div className="h-[46px] w-[46px] rounded-full border border-[#F3F5F7] p-3">
							<NabCompanySVG className="" />
						</div>
					)}

					<span className="line-clamp-1 hidden grow text-start text-sm font-semibold sm:flex">
						{profileData?.data?.name || 'Company Name'}
					</span>
				</div>
				<ArrowDown className={`transition-all duration-500 ${toggleModal ? '' : 'rotate-180'}`} />
			</button>

			{/* Modal section */}
			<div
				className={`absolute end-0 -z-[100] w-screen rounded-lg bg-white px-8 py-5 shadow-md transition-all duration-300 sm:start-0 sm:w-full ${
					toggleModal ? 'top-[60px] sm:top-[80px]' : '-top-[400px] scale-0'
				}`}
			>
				<div className="flex flex-col">
					{navbarMenuItems.map(({ logo: Logo, text, path, id, language, roles = [] }) => {
						if (roles.length && !roles.includes(currentUserRole)) {
							return null;
						}

						return (
							<Link
								to={path}
								key={id}
								onClick={() => handleToggleOutClick(text)}
								className="z-30 flex items-center gap-3 py-[10px] font-medium text-[#5F6D83]"
							>
								<Logo />
								<div className="relative flex w-full items-center gap-1 text-left">
									<button
										type="button"
										onClick={() => setLanguageModal((oldState) => !oldState)}
										className="w-full bg-white text-left hover:text-[#1098FF]"
									>
										{text}{' '}
										{text === 'Language' && (
											<span className="ml-3 text-sm">
												{JSON.parse(currentLan)?.value === 'bn' ? 'বাংলা' : 'English'}
											</span>
										)}
									</button>

									{text === 'Language' && (
										<div
											className={` static-0 absolute top-0 -z-30 transition-all duration-300 ${
												!languageModal
													? 'hidden scale-0'
													: 'absolute -start-[220px] -top-[28px] z-30 min-w-[150px] rounded-md bg-white shadow-xl'
											}`}
										>
											<ul>
												<button
													type="button"
													onClick={() => handleLanguageChange('English')}
													className="block w-full px-6 py-2 text-left hover:bg-blue-100"
												>
													English
												</button>
												<button
													type="button"
													onClick={() => handleLanguageChange('বাংলা')}
													className="block w-full px-6 py-2 text-left hover:bg-blue-100"
												>
													বাংলা
												</button>
											</ul>
										</div>
									)}
								</div>
							</Link>
						);
					})}
				</div>

				<button
					type="button"
					className="group mt-[10px] flex w-full cursor-pointer items-center gap-2 rounded-md px-1 font-Montserrat text-base font-medium text-[#E24A28]"
					onClick={handleLogOut}
				>
					<LogoutSVG />
					{t('Log Out')}
				</button>
			</div>
		</div>
	);
};

export default NavBarCompanyDropdown;

/* {language && (
    <>
      <select
        name="language"
        id="language"
        className="w-[60%] border-none text-[12px] outline-none"
        value={language}
        onChange={handleLanguageChange}
      >
        <option
          value={
            currentLan && JSON.parse(currentLan)?.value === 'en' ? 'English' : 'বাংলা'
          }
        >
          {currentLan && JSON.parse(currentLan)?.value === 'en' ? 'English' : 'বাংলা'}
        </option>
        <option
          value={
            currentLan && JSON.parse(currentLan)?.value === 'en' ? 'বাংলা' : 'English'
          }
        >
          {currentLan && JSON.parse(currentLan)?.value === 'en' ? 'বাংলা' : 'English'}
        </option>
      </select>
    </>
  )} */
