/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable prettier/prettier */
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/authReducer';

export const AuthContext = createContext();

export const AuthProvider = ({ children = null }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = { state, dispatch };
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
