import api from './config/axiosInstance';

const jobAPIs = {
	async createJobAPI(data) {
		const response = await api.post('/jobs', data);
		return response.data;
	},

	async getTags() {
		const response = await api.get('/tags');
		return response.data;
	},

	async getSingleJobs(id) {
		const response = await api.get(`/jobs/${id}`);
		return response?.data?.data;
	},

	async getJobs(query) {
		const response = await api.get(`/jobs?query=${query}`);
		return response.data.data;
	},

	async updateJobAPI({ updateJobData, id }) {
		const response = await api.put(`/jobs/${id}`, updateJobData);
		return response.data;
	},
};

export const { getTags, createJobAPI, getSingleJobs, getJobs, updateJobAPI } = jobAPIs;

export default jobAPIs;
