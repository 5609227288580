const ViewApplicantsSVG = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="0.25"
				y="0.25"
				width="23.5"
				height="23.5"
				rx="3.75"
				stroke="#A6AFBD"
				strokeWidth="0.5"
			/>
			<path
				d="M12.1652 19.9946L6.77932 19.9933C6.30695 19.9932 5.85398 19.8054 5.52005 19.4713C5.18612 19.1373 4.99859 18.6842 4.9987 18.2118L5.00152 6.78105C5.00163 6.30869 5.18939 5.85572 5.52348 5.52179C5.85758 5.18786 6.31064 5.00032 6.783 5.00044L15.0293 5.00247C15.5016 5.00258 15.9546 5.19034 16.2885 5.52443C16.6225 5.85853 16.81 6.31159 16.8099 6.78395L16.8088 11.0585C16.8141 11.1143 16.8076 11.1706 16.7899 11.2237C16.7721 11.2769 16.7434 11.3257 16.7057 11.3672C16.668 11.4086 16.622 11.4417 16.5707 11.4644C16.5195 11.487 16.464 11.4987 16.408 11.4987C16.3519 11.4987 16.2965 11.487 16.2452 11.4643C16.194 11.4416 16.148 11.4085 16.1103 11.367C16.0726 11.3256 16.044 11.2767 16.0262 11.2235C16.0085 11.1704 16.0021 11.1141 16.0073 11.0583L16.0084 6.78376C16.0084 6.65669 15.9834 6.53086 15.9347 6.4135C15.886 6.29613 15.8146 6.18954 15.7246 6.09983C15.6346 6.01012 15.5278 5.93907 15.4102 5.89074C15.2927 5.8424 15.1668 5.81775 15.0397 5.81819L6.7828 5.81616C6.65514 5.81471 6.52845 5.83861 6.41009 5.88646C6.29172 5.93431 6.18402 6.00516 6.09322 6.09492C6.00243 6.18468 5.93034 6.29156 5.88113 6.40937C5.83192 6.52717 5.80657 6.65357 5.80655 6.78125L5.80374 18.2227C5.8037 18.3504 5.82898 18.4768 5.87814 18.5946C5.92729 18.7125 5.99932 18.8194 6.09007 18.9092C6.18083 18.999 6.28849 19.0699 6.40683 19.1178C6.52518 19.1657 6.65185 19.1897 6.77951 19.1883L12.1654 19.1896C12.2722 19.1896 12.3745 19.2321 12.45 19.3076C12.5255 19.3831 12.5679 19.4855 12.5678 19.5922C12.5678 19.699 12.5254 19.8013 12.4499 19.8768C12.3744 19.9523 12.272 19.9947 12.1652 19.9946Z"
				fill="#5F6D83"
			/>
			<path
				d="M14.2804 9.30503L7.51241 9.30336C7.45661 9.30861 7.40032 9.30216 7.34716 9.2844C7.294 9.26664 7.24513 9.23798 7.20369 9.20024C7.16225 9.16251 7.12914 9.11654 7.10649 9.06527C7.08384 9.014 7.07215 8.95857 7.07217 8.90252C7.07218 8.84647 7.0839 8.79104 7.10657 8.73978C7.12925 8.68853 7.16238 8.64257 7.20384 8.60485C7.2453 8.56714 7.29418 8.5385 7.34735 8.52077C7.40052 8.50304 7.45681 8.49661 7.51261 8.50189L14.2806 8.50355C14.3804 8.51299 14.473 8.55931 14.5404 8.63343C14.6079 8.70756 14.6452 8.80417 14.6452 8.90438C14.6452 9.00459 14.6078 9.10118 14.5403 9.17527C14.4728 9.24937 14.3802 9.29563 14.2804 9.30503Z"
				fill="#5F6D83"
			/>
			<path
				d="M11.2458 12.431L7.52341 12.4301C7.41666 12.4301 7.31429 12.3877 7.23882 12.3122C7.16335 12.2367 7.12097 12.1343 7.12099 12.0275C7.12102 11.9208 7.16345 11.8184 7.23896 11.7429C7.31446 11.6675 7.41686 11.6251 7.52361 11.6251L11.246 11.626C11.3528 11.626 11.4551 11.6685 11.5306 11.744C11.6061 11.8195 11.6484 11.9219 11.6484 12.0286C11.6484 12.1354 11.606 12.2378 11.5305 12.3132C11.455 12.3887 11.3526 12.4311 11.2458 12.431Z"
				fill="#5F6D83"
			/>
			<path
				d="M16.66 16.0447C16.2827 16.046 15.9136 15.9353 15.5993 15.7267C15.2851 15.5181 15.0398 15.2209 14.8945 14.8728C14.7493 14.5246 14.7106 14.1412 14.7834 13.7711C14.8562 13.401 15.0373 13.0608 15.3036 12.7936C15.5699 12.5265 15.9095 12.3443 16.2793 12.2703C16.6492 12.1963 17.0328 12.2337 17.3814 12.3778C17.73 12.5219 18.028 12.7663 18.2376 13.0799C18.4473 13.3935 18.5591 13.7622 18.559 14.1394C18.5589 14.6436 18.359 15.1272 18.003 15.4843C17.6471 15.8414 17.1642 16.0429 16.66 16.0447ZM16.6607 12.878C16.411 12.8765 16.1665 12.9492 15.9582 13.0869C15.7499 13.2245 15.5871 13.4209 15.4905 13.6512C15.3939 13.8814 15.3678 14.1352 15.4156 14.3803C15.4634 14.6254 15.5829 14.8507 15.7589 15.0278C15.9349 15.205 16.1596 15.3258 16.4044 15.3751C16.6491 15.4244 16.9031 15.3999 17.1339 15.3047C17.3648 15.2096 17.5622 15.048 17.7011 14.8405C17.84 14.633 17.9142 14.389 17.9143 14.1393C17.9144 13.8064 17.7825 13.4871 17.5475 13.2513C17.3125 13.0155 16.9936 12.8826 16.6607 12.8815L16.6607 12.878Z"
				fill="#1098FF"
			/>
			<path
				d="M13.7354 20.2463C13.6295 20.2463 13.5278 20.2049 13.452 20.131C13.3761 20.0572 13.3321 19.9566 13.3294 19.8508C13.3583 18.002 15.0256 17.0442 16.6713 17.0446L16.6998 17.0447C18.2778 17.045 19.9732 17.936 19.9727 19.8453C19.9632 19.9459 19.9165 20.0395 19.8417 20.1075C19.7669 20.1756 19.6694 20.2133 19.5683 20.2133C19.4672 20.2132 19.3697 20.1755 19.295 20.1074C19.2202 20.0393 19.1735 19.9457 19.1641 19.8451C19.1644 18.4879 17.8822 17.8607 16.6925 17.8532C15.5028 17.8458 14.1597 18.4546 14.138 19.851C14.1361 19.9565 14.0928 20.057 14.0175 20.131C13.9422 20.2049 13.8409 20.2463 13.7354 20.2463Z"
				fill="#1098FF"
			/>
		</svg>
	);
};

export default ViewApplicantsSVG;
