const DateFormatted = (date) => {
	let updatedDate = '';
	function getDaySuffix(day) {
		if (day >= 11 && day <= 13) {
			return 'th';
		}

		switch (day % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	}
	try {
		const originalDate = new Date(date);

		const formattedDate = originalDate.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'short',
			// day: 'numeric',
		});

		const dayWithSuffix =
			new Intl.DateTimeFormat('en-US', { day: 'numeric' }).formatToParts(originalDate)[0].value +
			getDaySuffix(originalDate.getDate());

		const finalFormattedDate = `${dayWithSuffix} ${formattedDate}`;

		updatedDate = finalFormattedDate;
	} catch (error) {
		console.log(error);
	}
	return updatedDate;
};

export default DateFormatted;
