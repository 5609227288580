import applications from '../assets/svgs/applicants.svg';
import jobCircular from '../assets/svgs/job-circular.svg';
import SubscriptionSVG from '../assets/svgs/subscription.svg';
import talentPoolLogo from '../assets/svgs/TalentPoolsLogo.svg';
import { ADMIN, ASSISTANT, MANAGER } from '../constants/roles';

export const sidebarMenuItems = [
	{
		id: '18432c8e-2c53-4083-9c29-769a242f06ca6a',
		title: 'Job Circular',
		icon: jobCircular,
		name: 'JobCircular',
		component: null,
		path: '/jobs',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		id: '18432c8e-2c53-4083-9c29-769a422f06ca6b',
		title: 'Applicants',
		icon: applications,
		name: 'Applicants',
		component: null,
		path: '/jobs/applicants',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		id: '18432c8e-2c53-4083-9c29-769a223f06ch3i',
		title: 'Talent Pools',
		icon: talentPoolLogo,
		name: 'talentPools',
		component: null,
		path: '/talent-pool-job',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		id: '18432c8e-2c53-4083-9c29-73269a2f06ca6c',
		title: 'Subscriptions',
		icon: SubscriptionSVG,
		name: 'Subscriptions',
		component: null,
		path: '/subscription-plans/subscribed',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
];
