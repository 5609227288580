import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CollapseNavigation from '../../assets/svgs/CollapsNavigation';
import KeepNavOpen from '../../assets/svgs/KeepNavOpen';
import SideBarTogglerSVG from '../../assets/svgs/SideBarTogglerSVG';
import useAuth from '../../hooks/useAuth';
import { sidebarMenuItems } from '../../routes/sidebar-routes';
import SidebarItem from './SidebarItem';

const Sidebar = ({ isOpen, narrowSidebar, setNarrowSidebar }) => {
	const { t } = useTranslation();
	const [activeItem, setActiveItem] = useState(sidebarMenuItems[0].id);

	const location = useLocation();
	const { state: hasAccessToken } = useAuth();

	const handleItemClick = (item) => {
		setActiveItem(item?.id);
	};

	return (
		<aside
			className={`${
				hasAccessToken && location.pathname !== '/' ? '' : ''
			} h-screen bg-white sm:flex sm:flex-shrink-0`}
		>
			{/* <div
				className={`flex h-[100%] flex-col justify-between transition-all ${
					isOpen ? 'w-[234px]' : 'w-0 overflow-hidden lg:w-[234px]'
				}`}
			> */}
			<div
				className={`flex h-[100%] flex-col justify-between transition-all ${
					isOpen ? 'w-[234px]' : `${narrowSidebar ? 'w-[60px] ' : 'w-[234px]'}`
				}`}
			>
				<div className="relative mx-2 mt-32 flex flex-col items-center gap-2">
					{sidebarMenuItems.map((item) => (
						<SidebarItem
							item={item}
							key={item?.id}
							text={t(item?.title)}
							icon={item?.icon}
							path={item?.path}
							narrowSidebar={narrowSidebar}
							isActive={activeItem === item?.id}
							onClick={() => handleItemClick(item)}
						/>
					))}
				</div>

				{/* <div className="h-[58px] w-full text-right">
					<SidebarBottomMenus />
				</div> */}
				<div className="group relative mb-6 ml-auto mr-2 border">
					<button
						type="button"
						label="Surname"
						onClick={() => setNarrowSidebar((oldState) => !oldState)}
						className="mb-6 ml-auto mr-1 border"
					>
						<SideBarTogglerSVG />
						{!narrowSidebar ? (
							<CollapseNavigation className="absolute -top-1 left-12 z-50 h-0 w-0 transition-all group-hover:h-fit group-hover:w-fit" />
						) : (
							<KeepNavOpen className="absolute -top-1 left-12 z-50 h-0 w-0 transition-all group-hover:h-fit group-hover:w-fit" />
						)}
					</button>
				</div>
			</div>
		</aside>
	);
};

export default Sidebar;
