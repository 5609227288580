import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CrossSvg from '../../assets/svgs/CrossSvg';
import XXLText from '../atoms/XXLText';
import ApplyJobForm from '../molecules/ApplyJobForm';

const ApplyJobModal = ({ className = '', setApplicationModal = null, data }) => {
	const { t } = useTranslation();
	const [pageNo, setPageNo] = useState(1);
	const [submitted, setSubmitted] = useState(false);

	const handleClose = () => {
		setApplicationModal(false);
		setSubmitted(false);
		setPageNo(1);
	};

	return (
		// <div
		// 	className={`fixed start-0 top-0 z-[150] h-screen w-full overflow-y-auto bg-[#00000079] transition-all ${className} ${
		// 		(pageNo <= 1 || submitted) && 'items-center justify-center sm:flex'
		// 	}`}
		// >
		<div
			className={`fixed start-0 top-0 z-[150] h-screen w-full overflow-y-auto bg-[#00000079] transition-all ${className}`}
		>
			<div className="relative top-20 mx-auto w-full max-w-[970px] rounded-2xl bg-white p-10 md:p-20 xl:w-fit">
				<button
					type="button"
					aria-label="modalButton"
					onClick={() => handleClose()}
					className="absolute right-8 top-8 cursor-pointer"
				>
					<CrossSvg />
				</button>
				<XXLText>{t('Apply for this Job')}</XXLText>

				<div className="my-8 flex w-full flex-nowrap gap-[20px]">
					<div
						className={`h-2 w-full rounded-full  p-1 shadow-xl transition-all ${
							pageNo >= 1 ? 'bg-[#1098FF]' : 'bg-[#D9E1EC]'
						}`}
					/>

					{data?.data?.job?.questions?.length > 0 && (
						<div
							className={`h-2 w-full rounded-full  p-1 shadow-xl transition-all ${
								pageNo >= 2 ? 'bg-[#1098FF]' : 'bg-[#D9E1EC]'
							}`}
						/>
					)}

					<div
						className={`h-2 w-full rounded-full  p-1 shadow-xl transition-all ${
							pageNo >= 3 ? 'bg-[#1098FF]' : 'bg-[#D9E1EC]'
						}`}
					/>
				</div>

				<ApplyJobForm
					data={data}
					pageNo={pageNo}
					setPageNo={setPageNo}
					submitted={submitted}
					setSubmitted={setSubmitted}
					handleClose={handleClose}
				/>
			</div>
		</div>
	);
};

export default ApplyJobModal;
