export const languages = [
	{
		id: 'c7d9dad4-7fd7-47f8-95b2-ce7fe37fbbff',
		label: 'English',
		value: 'en',
		i18name: 'en',
	},
	{
		id: 'c7d9dad4-7fd7-47f8-95b2-ce7fe37fbbf5',
		label: 'বাংলা',
		value: 'bn',
		i18name: 'bn',
	},
];
