const SideBarTogglerSVG = () => {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="36" height="36" transform="matrix(-1 0 0 -1 36 36)" fill="#F7F9FD" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.5866 16.1481L19.7341 18.0006L21.5866 19.8531C21.6419 19.9046 21.6862 19.9667 21.7169 20.0357C21.7477 20.1047 21.7642 20.1792 21.7655 20.2547C21.7669 20.3303 21.753 20.4053 21.7247 20.4753C21.6964 20.5454 21.6543 20.609 21.6009 20.6624C21.5475 20.7158 21.4838 20.7579 21.4138 20.7862C21.3438 20.8145 21.2687 20.8284 21.1932 20.8271C21.1177 20.8257 21.0432 20.8092 20.9742 20.7785C20.9052 20.7477 20.8431 20.7034 20.7916 20.6481L18.5416 18.3981C18.4363 18.2926 18.3771 18.1497 18.3771 18.0006C18.3771 17.8516 18.4363 17.7086 18.5416 17.6031L20.7916 15.3531C20.8982 15.2538 21.0393 15.1997 21.185 15.2022C21.3307 15.2048 21.4698 15.2638 21.5728 15.3669C21.6759 15.47 21.7349 15.609 21.7375 15.7547C21.74 15.9005 21.686 16.0415 21.5866 16.1481Z"
				fill="#5F6D83"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.1875 25.5C24.5356 25.5 24.8694 25.3617 25.1156 25.1156C25.3617 24.8694 25.5 24.5356 25.5 24.1875V11.8125C25.5 11.088 24.912 10.5 24.1875 10.5H11.8125C11.4644 10.5 11.1306 10.6383 10.8844 10.8844C10.6383 11.1306 10.5 11.4644 10.5 11.8125V24.1875C10.5 24.5356 10.6383 24.8694 10.8844 25.1156C11.1306 25.3617 11.4644 25.5 11.8125 25.5H24.1875ZM24.375 24.1875C24.375 24.2372 24.3552 24.2849 24.3201 24.3201C24.2849 24.3552 24.2372 24.375 24.1875 24.375H15.75V11.625H24.1875C24.2372 11.625 24.2849 11.6448 24.3201 11.6799C24.3552 11.7151 24.375 11.7628 24.375 11.8125V24.1875ZM14.625 11.625V24.375H11.8125C11.7628 24.375 11.7151 24.3552 11.6799 24.3201C11.6448 24.2849 11.625 24.2372 11.625 24.1875V11.8125C11.625 11.7628 11.6448 11.7151 11.6799 11.6799C11.7151 11.6448 11.7628 11.625 11.8125 11.625H14.625Z"
				fill="#5F6D83"
			/>
		</svg>
	);
};

export default SideBarTogglerSVG;
