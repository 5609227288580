import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email address').required('Required'),
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	phone: Yup.string().required('Required').min(8, 'Too Short!').max(15, 'Too Long!'),
	presentAddress: Yup.string().required('Required'),
	parmanentAddress: Yup.string().required('Required'),
});
