/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

export const initialState =
	(localStorage !== undefined && JSON.parse(localStorage.getItem('language'))) || '';

export const types = {
	SET_LANGUAGE: 'SET_LANGUAGE',
	REMOVE_LANGUAGE: 'REMOVE_LANGUAGE',
};

// eslint-disable-next-line default-param-last
export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_LANGUAGE:
			return (state = action.payload);

		default:
			return state;
	}
};

export const setLanguage = (payload = '') => {
	return { type: types.SET_LANGUAGE, payload };
};
