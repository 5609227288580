/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

const TextInputField = ({
	type = 'text',
	name = 'textInput',
	placeholder = 'Type here ...',
	icon = null,
	customPlaceholder = null,
	className = '',
	error,
	errorClass = '',
	isRequired = false,
	...props
}) => {
	const { t } = useTranslation();

	return (
		<div className="w-full pb-3">
			<div className="relative flex w-full justify-between rounded-lg">
				<input
					required={isRequired}
					className={twMerge(
						'h-12 w-full rounded-md border-[#EFF1F3] px-6 font-medium text-[#02060C]  outline-none ring-0 placeholder:font-medium placeholder:text-[#A6AFBD] focus:outline-none focus:outline-0 focus:ring-0 sm:w-full',
						className
					)}
					type={type}
					name={name}
					placeholder={t(placeholder)}
					{...props}
				/>
				{icon && <div className="absolute -end-[10px] top-1/2 -translate-y-1/2">{icon}</div>}

				{customPlaceholder && (
					<span className="absolute start-[2px] top-1/2 z-10 me-0 flex h-[44px] -translate-y-1/2 items-center rounded-md bg-white px-3 pe-5 font-medium text-[#A6AFBD] min-[500px]:ps-6">
						{customPlaceholder}
					</span>
				)}
			</div>
			{error && (
				<div className={`mt-2 w-[56%] text-start text-sm text-red ${errorClass}`}>{t(error)}</div>
			)}
		</div>
	);
};

export default TextInputField;
