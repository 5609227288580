/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import TextInputField from './TextInputField';

const ApplyJobsThirdForm = ({
	// handleChange,
	// handleBlur,
	values,
	errors,
	touched,
	className = '',
	JobData,
	captcha = {},
	setCaptcha = null,
}) => {
	const captchaRef = useRef(null);

	const handleRecaptchaChange = (value) => {
		if (value) {
			setCaptcha((prevState) => ({ ...prevState, value }));
		}
	};

	// const handleRecaptchaExpire = (value) => {
	// 	setCaptcha((prevState) => ({ ...prevState, expired: true, value: '' }));
	// };

	useEffect(() => {
		setCaptcha((prevState) => ({ ...prevState, load: true }));
	}, [setCaptcha]);

	return (
		<div className={className}>
			{/* <p className="my-3 font-medium text-[#5F6D83]">LinkedIn profile*</p>
			<TextInputField
				type="text"
				name="first_question"
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.first_question}
				error={errors.first_question && touched.first_question && errors.first_question}
				className="h-[80px] border-0 bg-white px-0"
				required
				disabled
			/>

			<p className="my-3 font-medium text-[#5F6D83]">GitHub profile*</p>
			<TextInputField
				type="text"
				name="github"
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.github}
				error={errors.github && touched.github && errors.github}
				className="h-[80px] border-0 bg-white px-0"
				required
				disabled
			/> */}

			<div className="my-[10px] flex flex-wrap sm:flex-nowrap sm:gap-12">
				<TextInputField
					type="text"
					name="firstName"
					value={values.firstName}
					error={errors.firstName && touched.firstName && errors.firstName}
					placeholder="First Name"
					disabled
				/>
				<TextInputField
					type="text"
					name="lastName"
					value={values.lastName}
					placeholder="Last Name"
					disabled
				/>
			</div>
			<div className="my-[10px] flex flex-wrap sm:flex-nowrap sm:gap-12">
				<TextInputField
					type="email"
					name="email"
					value={values.email}
					error={errors.email && touched.email && errors.email}
					placeholder="Email"
					disabled
				/>
				<TextInputField
					type="Number"
					name="phone"
					value={values.phone}
					placeholder="Phone Number"
					disabled
				/>
			</div>

			<div className="my-[10px] flex flex-wrap sm:flex-nowrap sm:gap-12">
				{values.presentAddress && (
					<TextInputField
						type="text"
						name="presentAddress"
						value={values.presentAddress}
						placeholder="Present Address"
						disabled
					/>
				)}

				{values.parmanentAddress && (
					<TextInputField
						type="text"
						name="parmanentAddress"
						value={values.parmanentAddress}
						placeholder="Permanent Address"
						disabled
					/>
				)}
			</div>

			{JobData?.data?.job?.questions?.map((question, index) => (
				<div key={index}>
					<p className="my-3 font-medium text-[#5F6D83]">{question.question}</p>

					<TextInputField
						type="text"
						name={`Question_${index}`}
						value={values[`Question_${index}`]}
						className="min-h-[60px] px-3 text-start"
						error={errors.github && touched.github && errors.github}
						required
						disabled
					/>
				</div>
			))}

			{captcha.load && (
				<div className="mb-3 mt-2 flex items-center justify-center">
					<ReCAPTCHA
						style={{ display: 'inline-block' }}
						theme="dark"
						ref={captchaRef}
						sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
						onChange={handleRecaptchaChange}
						// onExpired={handleRecaptchaExpire}
					/>
				</div>
			)}
		</div>
	);
};

export default ApplyJobsThirdForm;
