/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import { getUserRole } from '../utils/compareUserRole';

export const types = {
	SET_CURRENT_USER_ROLE: 'SET_CURRENT_USER_ROLE',
	REMOVE_CURRENT_USER_ROLE: 'REMOVE_CURRENT_USER_ROLE',
};

export const initialState = getUserRole() || '';

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_CURRENT_USER_ROLE:
			return (state = action.payload);

		case types.REMOVE_CURRENT_USER_ROLE:
			return (state = '');

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

export const setCurrentUserRole = (payload = '') => {
	return { type: types.SET_CURRENT_USER_ROLE, payload };
};

export const removeCurrentUserRole = () => {
	return { type: types.REMOVE_CURRENT_USER_ROLE };
};
