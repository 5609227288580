import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import RoundedWrite from '../../assets/svgs/RoundedWrite';
import CopyLinkForm from '../atoms/CopyLinkForm';
import LText from '../atoms/LText';

const CongratulationModal = ({
	jobUrl = '',
	toggleCongratsModal = false,
	setToggleCongratsModal = null,
	disableRedirection = false,
	heading = 'Congratulation!',
	title = 'Your Job Post Have Been Successfully Published.',
	singleJobId,
	jobId = '',
}) => {
	const navigateTo = useNavigate();
	const { t } = useTranslation();

	if (toggleCongratsModal) {
		document.body.classList.add('modal-open');
	} else {
		document.body.classList.remove('modal-open');
	}

	const handleCloseModal = () => {
		setToggleCongratsModal(false);

		if (disableRedirection) {
			return;
		}
		navigateTo('/jobs');
	};

	return (
		<div
			className={`duration-400 fixed left-0 top-0 z-50 flex h-screen w-full items-center justify-center bg-[#0000004D] transition-all ${
				toggleCongratsModal ? '' : 'scale-0'
			}`}
		>
			<div className="rounded-[40px] bg-white px-[56px] py-[64px] text-center max-[500px]:h-screen max-[500px]:w-screen max-[500px]:rounded-none max-[500px]:pt-36">
				<RoundedWrite className="mx-auto text-[#5AB180] max-[500px]:h-[100px] max-[500px]:w-[100px]" />
				<div className="mb-[40px] mt-[64px] flex flex-col items-center gap-[16px] max-[500px]:mt-10">
					<p className="text-[48px] font-semibold leading-[72px] text-black max-[500px]:text-3xl">
						{t(heading)}
					</p>
					<LText className="font-medium text-gray-400">{t(title)}</LText>

					<LText className="font-semibold text-gray-400">{t('Share Job Post')}</LText>
					<CopyLinkForm jobUrl={jobUrl} singleJobId={singleJobId} jobId={jobId} />

					{/* <div className="flex items-center gap-[16px]">
						<MText className="font-semibold text-gray-400">Post Job On:</MText>
						<div className="flex gap-[8px]">
							<FacebookIcon />
							<LinkedInIcon />
							<TwitterIcon />
						</div>
					</div> */}
				</div>

				<button
					onClick={() => handleCloseModal()}
					type="button"
					className="bg-colors-blue-200 h-[48px] rounded-[8px] bg-blue-200 px-[72px] py-[12px] text-white"
				>
					Ok
				</button>
			</div>
		</div>
	);
};

export default CongratulationModal;
