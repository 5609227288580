const RedCircleCrossSvg = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="0.338867"
				y="0.744141"
				width="17.1885"
				height="17.1885"
				rx="8.59425"
				fill="#FF0000"
			/>
			<path
				d="M4.0625 14.209L13.8028 4.46873"
				stroke="white"
				strokeWidth="2.57827"
				strokeLinejoin="round"
			/>
			<path
				d="M13.8028 14.209L4.0625 4.46875"
				stroke="white"
				strokeWidth="2.57827"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default RedCircleCrossSvg;
