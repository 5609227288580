const XXLText = ({ className = '', children = null }) => {
	return (
		<div
			className={`font-Montserrat text-[32px] font-semibold leading-[52px] text-black md:text-[40px] ${className}`}
		>
			{children}
		</div>
	);
};

export default XXLText;
