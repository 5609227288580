/* eslint-disable react/no-array-index-key */
import TextInputField from './TextInputField';

const ApplyJobsSecondFrom = ({
	handleChange,
	handleBlur,
	values,
	errors,
	touched,
	className = '',
	JobData,
}) => {
	return (
		<div className={className}>
			{JobData?.data?.job?.questions?.map((question, index) => (
				<div key={index}>
					<p className="my-3 font-medium text-[#5F6D83]">{question.question}</p>
					<TextInputField
						required
						type="text"
						name={`Question_${index}`}
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.Question_index}
						error={errors.github && touched.github && errors.github}
						className="h-[80px] bg-[#F7F9FD]"
					/>
				</div>
			))}
		</div>
	);
};

export default ApplyJobsSecondFrom;
