const CloseJobSVG = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="0.25"
				y="0.25"
				width="23.5"
				height="23.5"
				rx="3.75"
				stroke="#A6AFBD"
				strokeWidth="0.5"
			/>
			<g clipPath="url(#clip0_4837_32473)">
				<path d="M6 6L18 18" stroke="#E15241" strokeWidth="2" />
				<path d="M6 18L18 6" stroke="#E15241" strokeWidth="2" />
			</g>
			<defs>
				<clipPath id="clip0_4837_32473">
					<rect width="14" height="14" fill="white" transform="translate(5 5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default CloseJobSVG;
