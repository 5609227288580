const UpArrowSVG = ({ width = '10', height = '11', className = 'text-[#1098FF]', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			style={style}
			className={className}
			viewBox="0 0 10 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M8 6.5L5 3.5L2 6.5" stroke="currentColor" strokeWidth="1.5" />
		</svg>
	);
};

export default UpArrowSVG;
