/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { fetchNewAccessToken } from '../authAPIs';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const errorHandler = (error) => {
	const statusCode = error?.response?.status;

	if (statusCode && statusCode !== 401) {
		console.error(error);
	}

	return Promise.reject(error);
};

// Handle authentication errors (logout user, clear storage, etc.)
const handleAuthError = () => {
	localStorage.clear();
	window.location.href = '/signin';
};

export const refreshTokenHandler = async (error) => {
	const originalRequest = error.config;

	if (
		(error?.response?.status === 401 || error?.response?.status === 403) &&
		!originalRequest._retry &&
		!originalRequest.url.includes('/refresh-token')
	) {
		// Track retry attempts
		originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;

		// Check if we've exceeded max retries
		if (originalRequest._retryCount > MAX_RETRIES) {
			handleAuthError();
			return Promise.reject(new Error('Max retry attempts exceeded'));
		}

		try {
			originalRequest._retry = true;
			await delay(RETRY_DELAY * originalRequest._retryCount);

			const access_token = await fetchNewAccessToken();

			localStorage.setItem('accessToken', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
			originalRequest.headers.Authorization = `Bearer ${access_token}`;

			return axios(originalRequest);
		} catch (refreshError) {
			handleAuthError();
			return Promise.reject(refreshError);
		}
	}

	return Promise.reject(error);
};
