/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/languageReducer';

export const LanguageContext = createContext();

const LanguageProvider = ({ children = null }) => {
	// const [language, setLanguage] = useState('English');
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = { state, dispatch };

	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;
