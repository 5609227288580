const EyeClosed = ({ width = '16', height = '16', className = '', style = {}, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			className={className}
			style={style}
			// eslint-disable-next-line react/jsx-props-no-spreading, no-undef
			{...props}
		>
			<path
				fill="#5F6D83"
				d="m9.986 8.135.91-.909c.065.247.104.506.104.774 0 1.654-1.346 3-3 3-.268 0-.527-.039-.774-.105l.909-.909a1.991 1.991 0 0 0 1.851-1.851Zm5.951-.378c-.061-.11-.934-1.639-2.639-2.934l-.727.728A10.007 10.007 0 0 1 14.91 8C14.32 8.903 11.995 12 8 12a7.35 7.35 0 0 1-1.68-.199l-.822.822A8.47 8.47 0 0 0 8 13c5.234 0 7.83-4.563 7.937-4.757a.5.5 0 0 0 0-.486Zm-2.083-4.903-11 11A.498.498 0 0 1 2 13.5a.5.5 0 0 1 .146-.354l1.4-1.399C1.283 10.381.134 8.371.063 8.243a.5.5 0 0 1 0-.486C.171 7.563 2.766 3 8 3a8.42 8.42 0 0 1 3.528.765l1.618-1.618a.5.5 0 1 1 .707.707ZM4.292 11l1.265-1.265A2.98 2.98 0 0 1 5 8c0-1.654 1.346-3 3-3a2.98 2.98 0 0 1 1.735.557l1.024-1.023A7.362 7.362 0 0 0 8 4C4.006 4 1.68 7.097 1.088 8c.377.57 1.458 2.013 3.204 3.001ZM6.283 9.01 9.01 6.283A1.977 1.977 0 0 0 8 6c-1.103 0-2 .897-2 2 0 .37.108.712.283 1.01Z"
			/>
		</svg>
	);
};

export default EyeClosed;

/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.98635 8.13479L10.8951 7.22603C10.9612 7.47341 11 7.73203 11 8.00003C11 9.65429 9.65423 11 7.99998 11C7.73198 11 7.47335 10.9613 7.22598 10.8952L8.13473 9.98641C8.61506 9.95379 9.06731 9.74823 9.40774 9.4078C9.74817 9.06737 9.95373 8.61512 9.98635 8.13479ZM15.937 7.75716C15.8755 7.64653 15.0032 6.11816 13.2982 4.82291L12.5706 5.55053C13.8138 6.46466 14.6016 7.53078 14.9117 8.00078C14.3191 8.90316 11.9944 12 7.99998 12C7.40098 12 6.84398 11.9234 6.31985 11.8012L5.49823 12.6228C6.25361 12.8544 7.08385 13 7.99998 13C13.2341 13 15.8291 8.43704 15.937 8.24291C15.9783 8.16863 16 8.08503 16 8.00003C16 7.91503 15.9783 7.83144 15.937 7.75716ZM13.8535 2.85353L2.85348 13.8535C2.75586 13.9512 2.62786 14 2.49998 14C2.37211 14 2.24411 13.9512 2.14648 13.8535C2.10003 13.8071 2.06317 13.752 2.03802 13.6914C2.01288 13.6307 1.99994 13.5657 1.99994 13.5C1.99994 13.4344 2.01288 13.3694 2.03802 13.3087C2.06317 13.248 2.10003 13.1929 2.14648 13.1465L3.54598 11.747C1.28348 10.3812 0.133861 8.37053 0.0629858 8.24291C0.0216781 8.1686 0 8.08499 0 7.99997C0 7.91496 0.0216781 7.83134 0.0629858 7.75703C0.170861 7.56303 2.76586 3.00003 7.99998 3.00003C9.35335 3.00003 10.5256 3.30878 11.5277 3.76528L13.1465 2.14653C13.3418 1.95116 13.6582 1.95116 13.8535 2.14653C14.0487 2.34191 14.0488 2.65828 13.8535 2.85353ZM4.29161 11.0014L5.55748 9.73554C5.20786 9.24504 4.99998 8.64691 4.99998 8.00003C4.99998 6.34578 6.34573 5.00003 7.99998 5.00003C8.64685 5.00003 9.24498 5.20791 9.73548 5.55741L10.7591 4.53378C9.95035 4.20991 9.03135 4.00003 7.99998 4.00003C4.00561 4.00003 1.68086 7.09691 1.08836 7.99928C1.46461 8.56953 2.54611 10.013 4.29161 11.0014ZM6.28335 9.00953L9.00948 6.28341C8.7121 6.10778 8.36973 6.00003 7.99998 6.00003C6.89723 6.00003 5.99998 6.89728 5.99998 8.00003C5.99998 8.36978 6.10773 8.71216 6.28335 9.00953Z" fill="#5F6D83"/>
</svg>  */
