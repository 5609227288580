import { removeAccessToken, removeCookieItem, removeRefreshToken } from './cookies';

export const clearStorageAndRedirect = (history = '/') => {
	const lang = localStorage.getItem('language');
	const welcomeMessage = localStorage.getItem('welcome-message');

	localStorage.clear();
	removeAccessToken();
	removeRefreshToken();
	removeCookieItem('role');

	if (lang) {
		localStorage.setItem('language', lang);
	}

	if (welcomeMessage) {
		localStorage.setItem('welcome-message', welcomeMessage);
	}

	window.location.assign(history);
};
