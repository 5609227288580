/* eslint-disable no-nested-ternary */
import { Navigate, Route, Routes } from 'react-router-dom';

import { roles } from '../constants/roles';
import useAuth from '../hooks/useAuth';
import useCurrentUserRole from '../hooks/useCurrentUserRole';
import isEmpty from '../utils/isEmpty';

const AuthenticatedRoutes = ({ children = null, redirectTo = '/', route = {} }) => {
	const { state: isAuthenticated } = useAuth();
	const { state: userRole } = useCurrentUserRole();

	if (roles?.includes(userRole)) {
		const hasPermission = !isEmpty(route?.roles) && route?.roles?.includes(userRole);

		if (hasPermission) {
			return isAuthenticated ? children : <Navigate to={redirectTo} />;
		}
	}

	return <Navigate to="/page-not-found" />;
};

const UnAuthenticatedRoutes = ({ children = null, redirectTo = '/jobs' }) => {
	const { state: isAuthenticated } = useAuth();
	return !isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const routeConfig = (routes = []) => {
	if (!routes) return <div />;

	return (
		<Routes>
			{routes?.map((route) => {
				return (
					<Route
						key={route.name}
						path={route.path}
						element={
							route.public ? (
								<UnAuthenticatedRoutes>
									<route.component />
								</UnAuthenticatedRoutes>
							) : route.protected ? (
								<AuthenticatedRoutes route={route}>
									<route.component />
								</AuthenticatedRoutes>
							) : (
								<route.component />
							)
						}
					/>
				);
			})}
		</Routes>
	);
};

export default routeConfig;
