/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// import { useRef, useState } from 'react';

// import { IoSearchSharp } from 'react-icons/io5';
// import notification from '../../assets/images/notificationIcon.png';
// import useOutClick from '../../hooks/useOutClick';
import { useState } from 'react';
import JobsSearch from './JobsSearch';
import NavBarCompanyDropdown from './NavBarCompanyDropdown';
// import NotificationModal from './NotificationModal';

const DashboardNavbar = () => {
	// const notificationRef = useRef();
	const [toggleSearch, setToggleSearch] = useState(false);
	// const [openNotification, setOpenNotification] = useState(false);

	// useOutClick(notificationRef, () => {
	// 	setOpenNotification(false);
	// });

	return (
		<div className="fixed left-0 right-0 top-0 z-[100] inline-flex h-[88px] items-center justify-between bg-white py-4 sm:gap-[20px] sm:px-6">
			<JobsSearch toggleSearch={toggleSearch} setToggleSearch={setToggleSearch} />

			<div className={`ml-4 flex items-center gap-4 md:gap-8 ${toggleSearch && 'hidden'}`}>
				{/* <button
					type="button"
					onClick={() => setToggleSearch((oldState) => !oldState)}
					aria-label="text"
					className="block rounded-full bg-[#E8F0FE] md:hidden"
				>
					<IoSearchSharp className="m-2 rounded-full text-lg text-gray-300" />
				</button> */}
				{/* <div
					ref={notificationRef}
					className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[#F7F9FD] px-[4.5px] py-[3px] exs:relative"
				>
					<button type="button" onClick={() => setOpenNotification((oldState) => !oldState)}>
						<img src={notification} className="h-[24px] w-[24px]" alt="notification" />
					</button>
					<NotificationModal
						setOpenNotification={setOpenNotification}
						className={openNotification ? '' : 'scale-0'}
					/>
				</div> */}

				{/* <div className="font-Outfit flex items-center rounded-[26px] border border-[#1098FF] text-sm font-semibold text-[#1098FF]"> */}
				{/* <div className="rounded-[26px] border border-[#1098FF] py-[6px] pl-4 pr-2">
					<LanguageBtn />
				</div> */}
				<div className="h-[30px] border border-[#D5D5D5]" />

				{/* <div className="relative w-fit">
					<button
						type="button"
						onClick={() => {
							setToggleModal((oldState) => !oldState);
						}}
						className="flex items-center gap-3 font-semibold"
					>
						<div className="h-[50px] w-[50px] rounded-full border border-[#F3F5F7] p-3">
							<NabCompanySVG className="" />
						</div>
						<p className="text-start">EWN Bangladesh Limited</p>
						<ArrowDown
							className={`transition-all duration-500 ${toggleModal ? '' : 'rotate-180'}`}
						/>
					</button>
					<div className="absolute start-0 mt-8 w-full rounded-lg bg-white px-8 py-5 shadow-md">
						la
					</div>
				</div> */}
				<NavBarCompanyDropdown />
			</div>
		</div>
	);
};

export default DashboardNavbar;
