import { useEffect } from 'react';

const useOutClick = (ref, callback) => {
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (ref.current && !ref.current.contains(e.target)) {
				callback();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.addEventListener('mousedown', handleClickOutside);
		};
	}, [ref, callback]);

	return <div>useOutClick</div>;
};

export default useOutClick;
