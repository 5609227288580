const ChangePassSVG = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.9684 19.3318C17.9682 19.5497 17.8815 19.7586 17.7274 19.9127C17.5733 20.0668 17.3644 20.1535 17.1465 20.1537H6.69507C6.47714 20.1536 6.26817 20.067 6.11406 19.9129C5.95994 19.7589 5.87329 19.5499 5.87312 19.332V12.7249C5.87335 12.507 5.96001 12.2981 6.1141 12.144C6.26818 11.99 6.4771 11.9034 6.69499 11.9032H17.1465C17.3644 11.9035 17.5733 11.9902 17.7273 12.1442C17.8814 12.2983 17.9681 12.5072 17.9684 12.7251V19.3318ZM11.9209 3.85482C12.9351 3.85597 13.9074 4.25942 14.6245 4.97662C15.3416 5.69382 15.7448 6.66619 15.7458 7.68036V10.0636H8.09561V7.68013C8.09667 6.66595 8.50002 5.6936 9.21717 4.97645C9.93431 4.25929 10.9067 3.85591 11.9209 3.85482ZM17.5858 10.1002V7.67944C17.5885 6.93376 17.444 6.19488 17.1606 5.50517C16.8771 4.81546 16.4602 4.1885 15.9339 3.66025C15.4076 3.13201 14.7821 2.71286 14.0935 2.42687C13.4048 2.14087 12.6664 1.99365 11.9207 1.99365C11.175 1.99365 10.4366 2.14087 9.74794 2.42687C9.05926 2.71286 8.43382 3.13201 7.90749 3.66025C7.38117 4.1885 6.96431 4.81546 6.68085 5.50517C6.39738 6.19488 6.25287 6.93376 6.25561 7.67944V10.0999C5.6349 10.2047 5.07129 10.5257 4.66455 11.0061C4.2578 11.4866 4.03416 12.0954 4.0332 12.7249V19.3318C4.03395 20.0375 4.31464 20.7141 4.81367 21.2132C5.3127 21.7122 5.98932 21.9929 6.69507 21.9937H17.1465C17.8522 21.9931 18.5289 21.7125 19.0279 21.2135C19.527 20.7145 19.8077 20.0379 19.8083 19.3322V12.7249C19.8075 12.0954 19.5838 11.4865 19.177 11.006C18.7702 10.5256 18.2066 10.2049 17.5858 10.1002Z"
				fill="#1098FF"
			/>
			<circle cx="12" cy="16" r="2" fill="#1098FF" />
		</svg>
	);
};

export default ChangePassSVG;
