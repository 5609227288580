import axios from 'axios';

import { clearStorageAndRedirect } from '../utils/clearStorageAndRedirect';
import { getRefreshToken, setAccessToken, setCookieItem, setRefreshToken } from '../utils/cookies';
import authAPIsAxiosInstance from './config/authAPIsAxiosInstance';
import baseURL from './config/baseURL';

// const authAPIsAxiosInstance = axios.create({
// 	baseURL: `${baseURL}/api/en/v1/users`,
// 	timeout: 10000,
// });

const authAPI = {
	async registerOrSignUp(data) {
		const response = await authAPIsAxiosInstance.post('/register', data);
		return response.data;
	},

	// eslint-disable-next-line consistent-return
	async fetchNewAccessToken() {
		const refresh = getRefreshToken();

		if (!refresh) {
			clearStorageAndRedirect('/');
		}

		try {
			const response = await authAPIsAxiosInstance.post(`/refresh-token`, {
				refreshToken: refresh,
			});

			if ([200, 201].includes(response?.status)) {
				const { accessToken, refreshToken } = response.data.data;

				if (accessToken) {
					setAccessToken(accessToken, { path: '/' });
				}

				if (refreshToken) {
					setRefreshToken(refreshToken, { path: '/' });
				}
			}

			if ([400, 401, 403, 500].includes(response?.status)) {
				clearStorageAndRedirect('/');
			}

			return response.data?.data.accessToken;
		} catch (error) {
			if (error?.response?.status === 500) {
				clearStorageAndRedirect('/');
			}
		}
	},

	// eslint-disable-next-line consistent-return
	async fetchTokensOrSignIn(data = {}) {
		try {
			const response = await authAPIsAxiosInstance.post(`/login`, data);

			if ([200, 201].includes(response.status)) {
				// eslint-disable-next-line no-unsafe-optional-chaining
				const { accessToken, refreshToken, role } = response?.data?.data;

				if (accessToken && refreshToken && role) {
					setAccessToken(accessToken, { path: '/' });
					setRefreshToken(refreshToken, { path: '/' });
					setCookieItem('role', role, { path: '/' });
				}

				return response?.data;
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message);
		}
	},

	async signupOtpAPI(data = {}) {
		const response = await axios.post(`${baseURL}/api/en/v1/users/register/verify`, data);
		return response.data;
	},
};

export const signUpViaInvitation = async (data = {}) => {
	const response = await authAPIsAxiosInstance.post('/invitation/register', data);
	return response.data;
};

export const { fetchNewAccessToken, registerOrSignUp, fetchTokensOrSignIn, signupOtpAPI } = authAPI;

export default authAPI;
