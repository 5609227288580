import { useTranslation } from 'react-i18next';

const Button = ({ className = '', title = 'Sign In', ...buttonProps }) => {
	const { t } = useTranslation();
	return (
		// eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
		<button className={`${className}`} {...buttonProps}>
			{t(title)}
		</button>
	);
};
export default Button;
