const JobCardPill = ({ children, className }) => {
	return (
		<span
			style={{
				border: '1px solid #A6AFBD',
			}}
			className={`flex w-fit min-w-[100px] items-center justify-center gap-1 rounded-md px-4 py-2 text-sm font-semibold leading-[150%] text-[#5F6D83] ${className}`}
		>
			{children}
		</span>
	);
};

export default JobCardPill;
