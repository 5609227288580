/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import toast, { LoaderIcon } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useState } from 'react';
import { submitApplication } from '../../apis/applicationAPIs';
import LongRightArrow from '../../assets/svgs/LongRightArrow';
import { validationSchema } from '../../validations/publicApplication/applicationForm';
import ApplyJobsFirstForm from '../atoms/ApplyJobsFirstForm';
import ApplyJobsSecondFrom from '../atoms/ApplyJobsSecondFrom';
import ApplyJobsThirdForm from '../atoms/ApplyJobsThirdForm';

const ApplyJobForm = ({
	data: JobData,
	pageNo,
	setPageNo,
	submitted = false,
	setSubmitted = null,
	handleClose = null,
}) => {
	const [captcha, setCaptcha] = useState({
		callback: 'not fired',
		value: '',
		load: false,
		expired: false,
	});

	const id = useParams();
	const { t } = useTranslation('');
	const [isResume, setIsResume] = useState(true);
	const [fileName, setFileName] = useState('');

	// const validateForm = (values) => {
	// 	const errors = {};

	// 	if (!values.email) {
	// 		errors.email = 'Required';
	// 	} else if (
	// 		!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
	// 			values.email
	// 		)
	// 	) {
	// 		errors.email = 'Invalid email address';
	// 	}
	// 	if (!values.firstName) {
	// 		errors.firstName = 'Required';
	// 	}
	// 	if (!values.lastName) {
	// 		errors.lastName = 'Required';
	// 	}
	// 	if (!values.phone) {
	// 		errors.phone = 'Required';
	// 	} else if (!/^([01]|\+88)?\d{10}/i.test(values.phone)) {
	// 		errors.phone = 'Invalid phone number';
	// 	}
	// 	if (!values.presentAddress) {
	// 		errors.presentAddress = 'Required';
	// 	}
	// 	if (!values.parmanentAddress) {
	// 		errors.parmanentAddress = 'Required';
	// 	}
	// 	if (!values.resume) {
	// 		errors.resume = 'Required';
	// 	}

	// 	return errors;
	// };

	const { mutateAsync, isPending, isSuccess } = useMutation({
		mutationFn: submitApplication,
		// eslint-disable-next-line no-unused-vars
		onSuccess: (data) => {
			if (data?.status === 200 || data?.status === 201) {
				toast.success('Thanks. Your application successfully submitted.');
				handleClose();
				setSubmitted(true);

				setTimeout(() => {
					window.location.reload();
				}, 2000);
				setSubmitted(true);
				handleClose();
			}

			if (data?.response?.status === 403) {
				toast.error(data?.response?.data?.message);
			}
		},
	});

	const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
		const response = await mutateAsync({
			jobId: JobData?.data?.job?.id,
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			phone: values.phone,
			// profile: 'https://i.ibb.co/jkYLBVn/download.png',
			profile: `https://api.dicebear.com/9.x/dylan/svg?seed=${`${values.firstName} ${values.lastName}`}&backgroundType=gradientLinear&radius=50&size=64`,
			presentAddress: values.presentAddress,
			parmanentAddress: values.parmanentAddress,
			resume: values.resume,
			rating: 5,
			questionAboutUs: '',
			whyInterested: '',
			answers: [
				JobData?.data?.job?.questions?.map((question, index) => ({
					question: question.question,
					answer: values[`Question_${index}`],
				})),
			],
		});

		if (response?.status === 201 || response?.status === 200) {
			// toast.success('Thanks. Your application successfully submitted.');
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			setSubmitted(true);
			handleClose();
			resetForm();
			setFileName('');
		}

		if (response?.status === 403 || response?.status === 404) {
			if (response?.data?.message) {
				toast.error(response?.data?.message);
			} else {
				toast.error('Could not process your request. Please try again.');
			}
		}

		// handleClose();
		// setSubmitting(false);
		// resetForm();
		// setFileName('');
	};

	const handleSetNextPage = () => {
		if (JobData?.data?.job?.questions?.length > 0) {
			setPageNo((pre) => pre + 1);
		} else {
			setPageNo(3);
		}
	};

	const handleSetPreviousPage = () => {
		if (JobData?.data?.job?.questions?.length > 0) {
			setPageNo((prev) => prev - 1);
		} else {
			setPageNo(1);
		}
	};

	return (
		<>
			<div className={submitted ? 'h-0 overflow-hidden' : ''}>
				<Formik
					initialValues={{
						email: '',
						firstName: '',
						lastName: '',
						phone: '',
						presentAddress: '',
						parmanentAddress: '',
						resume: null,
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmitForm}
				>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
						<form onSubmit={handleSubmit}>
							<ApplyJobsFirstForm
								className={`h-0 overflow-hidden transition-all ${
									pageNo === 1 && 'h-fit overflow-auto'
								}`}
								handleChange={handleChange}
								handleBlur={handleBlur}
								values={values}
								errors={errors}
								touched={touched}
								setIsResume={setIsResume}
								fileName={fileName}
								setFileName={setFileName}
							/>

							<ApplyJobsSecondFrom
								className={`h-0 overflow-hidden transition-all ${
									pageNo === 2 && 'h-fit overflow-auto'
								}`}
								handleChange={handleChange}
								handleBlur={handleBlur}
								values={values}
								errors={errors}
								touched={touched}
								JobData={JobData}
							/>

							<ApplyJobsThirdForm
								className={`h-0 overflow-hidden transition-all ${
									pageNo === 3 && 'h-fit overflow-auto'
								}`}
								handleChange={handleChange}
								handleBlur={handleBlur}
								values={values}
								errors={errors}
								touched={touched}
								isSubmitting={isSubmitting}
								JobData={JobData}
								captcha={captcha}
								setCaptcha={setCaptcha}
							/>

							<div className="mb-10 flex justify-between">
								<button
									type="button"
									onClick={handleSetPreviousPage}
									className={`flex h-[64px] w-[140px] items-center justify-center gap-2 rounded-[8px] border-2 border-[#1098FF] font-semibold text-[#1098FF] disabled:cursor-not-allowed disabled:bg-gray-300 ${
										pageNo > 1 || 'hidden'
									}`}
								>
									<p>Back</p>
								</button>

								<button
									type="button"
									onClick={handleSetNextPage}
									disabled={pageNo === 1 && isResume}
									className={`ml-auto flex h-[64px] w-[140px] items-center justify-center gap-2 rounded-[8px] bg-[#1098FF] font-semibold text-white disabled:cursor-not-allowed disabled:bg-gray-300 ${
										pageNo === 3 && 'hidden'
									}`}
								>
									<p>Next</p>
									<LongRightArrow />
								</button>

								<button
									type="submit"
									disabled={isSubmitting || isPending || !captcha.value}
									className={`ml-auto flex h-[64px] items-center justify-center gap-2 rounded-[8px] bg-[#1098FF] px-8 font-semibold text-white disabled:cursor-not-allowed disabled:bg-gray-300 ${
										pageNo !== 3 && 'hidden'
									} ${isPending && 'bg-gray-300'}`}
								>
									<div className="flex items-center gap-2 whitespace-nowrap">
										{t('Submit Application')} {isPending && <LoaderIcon />}
									</div>
								</button>
							</div>
						</form>
					)}
				</Formik>
			</div>

			{/* <div
				className={`mt-[20px] flex h-0 flex-col items-center overflow-hidden ${
					submitted && 'h-fit'
				}`}
			>
				<p
					className="mb-[80px]
				text-center font-semibold text-[#5F6D83]"
				>
					You have received an e-mail with a verification code. Please check you inbox.
				</p>
				<OtpForm
					app="true"
					id={applicationId}
					email={applicantEmail}
					className="mx-[100px] flex max-w-[500px] flex-col items-center gap-16"
				/>
			</div> */}
		</>
	);
};

export default ApplyJobForm;
