import Spinner from '../atoms/Spinner';

const FullPageSpinner = () => {
	return (
		<div
			id="loading-screen"
			className="fixed left-0 top-0 z-50 block h-full w-full bg-white opacity-25"
		>
			<span className="relative top-1/2 mx-auto my-0 block h-0 w-0 opacity-100">
				<Spinner />
			</span>
		</div>
	);
};

export default FullPageSpinner;
