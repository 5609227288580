/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSVG from '../../assets/svgs/LanguageSVG';
import UpArrowSVG from '../../assets/svgs/UpArrowSVG';
import WriteSVG from '../../assets/svgs/WriteSVG';
import { languages } from '../../constants/languages';
import useLanguage from '../../hooks/useLanguage';
import useOutClick from '../../hooks/useOutClick';
import { setLanguage as setLanguageAction } from '../../reducers/languageReducer';

const LanguageBtn = ({ className = '' }) => {
	const languageRef = useRef();
	const currentLan = localStorage.getItem('language');
	const [toggleLanguage, setToggleLanguage] = useState(false);

	const { i18n } = useTranslation();
	const { dispatch } = useLanguage();

	useOutClick(languageRef, () => {
		setToggleLanguage(false);
	});

	const handleChangeLanguage = (item) => {
		dispatch(setLanguageAction(item?.value));
		localStorage.setItem('language', JSON.stringify(item));
		i18n?.changeLanguage(item.i18name);

		setToggleLanguage((oldState) => !oldState);
	};

	return (
		<div
			ref={languageRef}
			className={`flex cursor-pointer flex-row items-center justify-end ${className}`}
		>
			<button
				type="button"
				onClick={() => setToggleLanguage((oldState) => !oldState)}
				className="flex items-center gap-[4px] text-[14px] font-semibold text-[#1098FF]"
			>
				{currentLan && JSON.parse(currentLan)?.value === 'en' ? 'EN' : 'বাং'}
				<LanguageSVG />
			</button>
			<div
				className={`absolute top-14 w-[174px] rounded-[12px] bg-white p-[20px] shadow-xl transition-all duration-200 ${
					!toggleLanguage && 'scale-0'
				}`}
			>
				<table className="w-full">
					<thead>
						{languages.map((item) => (
							<tr
								key={item?.id}
								onClick={() => handleChangeLanguage(item)}
								className="w-full cursor-pointer text-white first:text-[#1098FF]"
							>
								<td>
									<WriteSVG
										className={
											JSON.parse(currentLan)?.label === item?.label
												? 'text-[#00FFAB]'
												: 'text-white'
										}
									/>
								</td>
								<td
									className={`py-[10px] ${
										JSON.parse(currentLan)?.label === item?.label ? 'text-[#1098FF]' : 'text-black'
									}`}
								>
									{item?.label}
								</td>
								<td>
									<UpArrowSVG className="" />
								</td>
							</tr>
						))}
					</thead>
				</table>
			</div>
		</div>
	);
};

export default LanguageBtn;
