const DeleteJob = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
			<rect
				width="23.5"
				height="23.5"
				x="0.25"
				y="0.25"
				stroke="#A6AFBD"
				strokeWidth="0.5"
				rx="3.75"
			/>
			<path
				fill="#E15241"
				d="M16.083 9.084a.583.583 0 00-.583.583v6.528a1.12 1.12 0 01-1.172 1.055H9.672A1.12 1.12 0 018.5 16.195V9.667a.583.583 0 00-1.167 0v6.528a2.286 2.286 0 002.34 2.222h4.655a2.286 2.286 0 002.339-2.222V9.667a.583.583 0 00-.584-.583zm.584-1.75h-2.334V6.167a.583.583 0 00-.583-.583h-3.5a.583.583 0 00-.583.583v1.167H7.333a.583.583 0 100 1.166h9.334a.583.583 0 000-1.167zm-5.834 0V6.75h2.334v.583h-2.334z"
			/>
			<path
				fill="#E15241"
				d="M11.417 14.917v-4.084a.583.583 0 00-1.167 0v4.084a.583.583 0 001.167 0zm2.333 0v-4.084a.583.583 0 00-1.167 0v4.084a.583.583 0 001.167 0z"
			/>
		</svg>
	);
};

export default DeleteJob;
