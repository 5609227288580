/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import QuillEditor from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'blockquote',
	'list',
	'align',
	'bullet',
	'indent',
	'link',
	'image',
	'color',
	'background',
	'code-block',
	'strike',
];

const RichtextEditorField = ({
	name = '',
	className = '',
	setFieldValue,
	defaultValue = '',
	hideToolbar = false,
	readOnly = false,
	...props
}) => {
	const quill = useRef();
	const [value, setValue] = useState(defaultValue || '');

	const imageHandler = useCallback(() => {
		// Create an input element of type 'file'
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();

		// When a file is selected
		input.onchange = () => {
			const file = input.files[0];
			const reader = new FileReader();

			// Read the selected file as a data URL
			reader.onload = () => {
				const imageUrl = reader.result;
				const quillEditor = quill.current.getEditor();

				// Get the current selection range and insert the image at that index
				const range = quillEditor.getSelection(true);
				quillEditor.insertEmbed(range.index, 'image', imageUrl, 'user');
			};

			reader.readAsDataURL(file);
		};
	}, []);

	const modules = useMemo(
		() => ({
			toolbar: {
				container: [
					[{ header: [1, 2, 3, 4, 5, false] }],
					['bold', 'italic', 'underline', 'blockquote', 'strike'],
					[{ color: [] }, { background: [] }],
					[
						{ align: 'center' },
						{ align: 'right' },
						{ align: 'justify' },
						{ indent: '-1' },
						{ indent: '+1' },
						{ list: 'ordered' },
						{ list: 'bullet' },
					],
					[{ direction: 'rtl' }, { script: 'sub' }, { script: 'super' }],
					['link', 'image'],
				],
				handlers: {
					image: imageHandler,
				},
			},
			clipboard: {
				matchVisual: true,
			},
		}),
		[imageHandler]
	);

	const handleOnchange = (data) => {
		if (props?.readOnly) return;

		setValue(data);

		if (setFieldValue) {
			setFieldValue(name, data);
		}
	};

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	return (
		<div className="">
			<QuillEditor
				ref={(el) => (quill.current = el)}
				theme="snow"
				formats={!hideToolbar ? formats : {}}
				modules={!hideToolbar ? modules : { toolbar: false }}
				placeholder="Write a description about this job..."
				className={`${className}`}
				name={name}
				value={value}
				onChange={handleOnchange}
				readOnly={readOnly}
				{...props}
			/>
		</div>
	);
};

export default RichtextEditorField;
