const LogoutSVG = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.4375 11.4375H13.3125C13.002 11.4375 12.75 11.1855 12.75 10.875C12.75 10.5645 13.002 10.3125 13.3125 10.3125H20.4375C20.748 10.3125 21 10.5645 21 10.875C21 11.1855 20.748 11.4375 20.4375 11.4375Z"
				fill="#E15241"
			/>
			<path
				d="M17.625 14.25C17.5511 14.2502 17.4779 14.2357 17.4097 14.2074C17.3414 14.179 17.2795 14.1375 17.2274 14.085C17.0077 13.8652 17.0077 13.509 17.2274 13.2892L19.6425 10.8743L17.2274 8.45924C17.0077 8.23951 17.0077 7.88327 17.2274 7.66355C17.4473 7.44368 17.8035 7.44368 18.0233 7.66355L20.8358 10.476C21.0555 10.6958 21.0555 11.052 20.8358 11.2717L18.0233 14.0842C17.9712 14.1369 17.9091 14.1786 17.8408 14.2071C17.7724 14.2356 17.6991 14.2501 17.625 14.25ZM9.00005 21C8.83952 21 8.68723 20.9775 8.53504 20.9302L4.02146 19.4265C3.40729 19.212 3 18.6397 3 18V4.50005C3 3.67279 3.67279 3 4.50005 3C4.66043 3 4.81276 3.0225 4.96506 3.06975L9.47849 4.57352C10.0928 4.78801 10.5 5.36028 10.5 5.99995V19.5C10.5 20.3272 9.82731 21 9.00005 21ZM4.50005 4.125C4.29379 4.125 4.125 4.29375 4.125 4.50005V18C4.125 18.1598 4.23223 18.3082 4.38523 18.3615L8.87767 19.8585C8.90994 19.869 8.95199 19.875 9.00005 19.875C9.20631 19.875 9.37495 19.7062 9.37495 19.5V5.99995C9.37495 5.84024 9.26773 5.69177 9.11473 5.63851L4.62229 4.14149C4.59001 4.13105 4.54796 4.125 4.50005 4.125Z"
				fill="#E15241"
			/>
			<path
				d="M14.4374 9.00005C14.1269 9.00005 13.8749 8.74805 13.8749 8.43755V5.06255C13.8749 4.54575 13.4543 4.125 12.9375 4.125H4.5C4.1895 4.125 3.9375 3.873 3.9375 3.5625C3.9375 3.252 4.1895 3 4.5 3H12.9375C14.0753 3 14.9999 3.92479 14.9999 5.06255V8.43755C14.9999 8.74805 14.7479 9.00005 14.4374 9.00005ZM12.9375 18.75H9.93741C9.62691 18.75 9.37491 18.498 9.37491 18.1875C9.37491 17.877 9.62691 17.625 9.93741 17.625H12.9375C13.4543 17.625 13.8749 17.2042 13.8749 16.6875V13.3125C13.8749 13.002 14.1269 12.75 14.4374 12.75C14.7479 12.75 14.9999 13.002 14.9999 13.3125V16.6875C14.9999 17.8252 14.0753 18.75 12.9375 18.75Z"
				fill="#E15241"
			/>
		</svg>
	);
};

export default LogoutSVG;
