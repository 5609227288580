import { useTranslation } from 'react-i18next';

const LoginFormHeader = () => {
	const { t } = useTranslation();

	return (
		<div>
			<h4 className="text-gray-0 text-center font-Montserrat text-lg font-normal leading-[21.94px]">
				{t('Welcome to')}
			</h4>
			<h3 className="mt-2 text-center font-Montserrat text-xl font-bold leading-[29.26px] text-black">
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				{t('HrClerks ATS')}
			</h3>
		</div>
	);
};
export default LoginFormHeader;
