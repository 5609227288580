import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CloseJobSVG from '../../assets/svgs/CloseJobSVG';
import DeleteJob from '../../assets/svgs/DeleteJob';
import UpdateSVG from '../../assets/svgs/UpdateSVG';
import ViewApplicantsSVG from '../../assets/svgs/ViewApplicantsSVG';
import { ASSISTANT } from '../../constants/roles';
import useCurrentUserRole from '../../hooks/useCurrentUserRole';

const CustomDropdown = ({
	published = '',
	expired = '',
	id = null,
	dropIcon = null,
	handleConfirmationModal,
	handleCloseJob,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { state: currentUserRole } = useCurrentUserRole();

	let updateName = '';
	if (!published) {
		updateName = 'Update Draft';
	} else if (expired) {
		updateName = 'Reopen Job';
	} else {
		// eslint-disable-next-line no-unused-vars
		updateName = 'Update Job';
	}

	return (
		<Menu as="div" className="relative z-40 inline-block text-left">
			<Menu.Button className="h-full rounded-full px-2">{dropIcon}</Menu.Button>
			<Transition
				className="rounded-[8px]"
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 mt-2 w-[200px] origin-top-right cursor-pointer divide-y-[1.5px] divide-[#0000001A] rounded-lg bg-white p-4 pt-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
					{published && (
						<Menu.Item
							onClick={() => navigate(`/jobs/applicants/${id}`)}
							className="pt-2 font-medium text-[#02060C] hover:text-[#1098FF]"
						>
							{({ active }) => (
								<div className={`mb-2 flex gap-2 ${active && 'bg-[#fff]'}`}>
									<ViewApplicantsSVG />
									<p>{t('View Applicants')}</p>
								</div>
							)}
						</Menu.Item>
					)}

					{currentUserRole !== ASSISTANT && (
						<Menu.Item className="pt-2 font-medium text-[#02060C] hover:text-[#1098FF]">
							{({ active }) => (
								<Link
									to={`/jobs/edit/${id}`}
									className={`mb-2 flex gap-2 ${active && 'bg-[#fff]'}`}
								>
									<UpdateSVG />
									<p>{t(updateName)}</p>
								</Link>
							)}
						</Menu.Item>
					)}

					{published && !expired && currentUserRole !== ASSISTANT && (
						<Menu.Item
							onClick={() => handleCloseJob(id)}
							className="pt-2 font-medium text-[#02060C] hover:text-[#1098FF]"
						>
							{({ active }) => (
								<div className={`mb-2 flex gap-2 ${active && 'bg-[#fff]'}`}>
									<CloseJobSVG />
									<p>{t('Close Job')}</p>
								</div>
							)}
						</Menu.Item>
					)}

					{!published && currentUserRole !== ASSISTANT && (
						<Menu.Item
							onClick={() => handleConfirmationModal(id)}
							className="pt-2 font-medium text-[#02060C] hover:text-[#1098FF]"
						>
							{({ active }) => (
								<div className={`mb-2 flex gap-2 ${active && 'bg-[#fff]'}`}>
									<DeleteJob />
									<p>{t('Delete Draft')}</p>
								</div>
							)}
						</Menu.Item>
					)}
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default React.memo(CustomDropdown);
