const EyeOpen = ({ width = '16', height = '16', className = '', style = {}, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			className={className}
			style={style}
			fill="none"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<path
				fill="#5F6D83"
				d="M8 13C2.766 13 .17 8.437.063 8.243a.5.5 0 0 1 0-.486C.171 7.563 2.766 3 8 3s7.83 4.563 7.937 4.757a.5.5 0 0 1 0 .486C15.829 8.437 13.234 13 8 13ZM1.088 8c.595.9 2.93 4 6.912 4 3.994 0 6.32-3.097 6.912-4-.595-.9-2.93-4-6.912-4-3.994 0-6.32 3.097-6.912 4ZM8 11c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3Zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2Z"
			/>
		</svg>
	);
};

export default EyeOpen;
