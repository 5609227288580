const RoundedWrite = ({ height = '120', width = '121', className = '', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={style}
		>
			<g clipPath="url(#clip0_506_16650)">
				<path
					d="M60.5 120C27.4166 120 0.5 93.0834 0.5 60C0.5 26.9166 27.4166 0 60.5 0C93.5834 0 120.5 26.9166 120.5 60C120.5 93.0834 93.5834 120 60.5 120ZM60.5 7.5C31.5509 7.5 8 31.0509 8 60C8 88.9491 31.5509 112.5 60.5 112.5C89.4491 112.5 113 88.9491 113 60C113 31.0509 89.4491 7.5 60.5 7.5Z"
					fill="currentColor"
					fillOpacity="0.5"
				/>
				<path
					d="M34.5 64L49.5 78.5L86.5 41.5"
					stroke="currentColor"
					strokeWidth="8"
					strokeLinecap="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_506_16650">
					<rect width="120" height="120" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default RoundedWrite;
