// eslint-disable-next-line consistent-return
const Typography = ({ variant, className = '', title }) => {
	if (variant === 'h1') {
		return <h1 className={`${className}`}>{title}</h1>;
	}

	if (variant === 'h2') {
		return <h2 className={`${className}`}>Hello</h2>;
	}

	if (variant === 'h3') {
		return <h3 className={`${className}`}>{title}</h3>;
	}

	if (variant === 'h4') {
		return <h4 className={`${className}`}>{title}</h4>;
	}

	if (variant === 'h5') {
		return <h5 className={`${className}`}>Hello</h5>;
	}

	if (variant === 'h6') {
		return <h6 className={`${className}`}>{title}</h6>;
	}

	if (variant === 'paragraph') {
		return (
			<p className="font-Montserrat text-white sm:text-base">
				<strong>{title}</strong>
			</p>
		);
	}
};

export default Typography;
