import { useTranslation } from 'react-i18next';

const ConformationModal = ({ handleConfirmationModal, handleJobDelete }) => {
	const { t } = useTranslation();
	const onClose = () => {
		handleConfirmationModal(false);
	};

	return (
		<div className="fixed inset-0 z-50 overflow-y-auto backdrop-blur-md">
			<div className="flex h-full items-center justify-center text-center">
				<div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
				<div className="relative mx-auto w-full transform overflow-hidden rounded-[10px] bg-[#FBFCFD] py-[50px] text-left shadow-xl transition-all md:w-[500px] lg:w-[600px]">
					<div className="absolute end-3 top-3 bg-white sm:end-6 sm:top-6">
						<button
							type="button"
							className="bg-warmGray-200 mb-2 h-10 w-10 cursor-pointer rounded-full font-bold shadow-md"
							onClick={onClose}
						>
							X
						</button>
					</div>

					<div className="mx-auto flex w-[75%] flex-col items-center justify-center gap-7 lg:w-[450px]">
						<h6 className="text-center text-xl font-normal leading-tight text-[#02060C] md:text-[48px]">
							{t('Are you sure?')}
						</h6>

						<div className="flex w-full justify-between gap-6 font-semibold">
							<button
								type="button"
								className="h-12 w-full rounded-md border-2 bg-[#1098FF] text-lg text-white"
								onClick={onClose}
							>
								{t('No')}
							</button>

							<button
								type="button"
								className="h-12 w-full rounded-md bg-rose-700 text-lg text-white"
								onClick={handleJobDelete}
							>
								{t('Yes')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConformationModal;
