import axios from 'axios';
import toast from 'react-hot-toast';
import { clearStorageAndRedirect } from '../../utils/clearStorageAndRedirect';
import api from '../config/axiosInstance';
import baseURL from '../config/baseURL';

const jobApis = {
	// eslint-disable-next-line no-unused-vars
	async getJobs(type = '', sort = '', location = '') {
		try {
			const option = new URLSearchParams();
			if (type) {
				option.append('type', type);
			}
			if (sort) {
				option.set('sort', sort);
			}
			if (location) {
				option.set('location', location);
			}
			const params = option.toString();
			const response = await api.get(`/jobs?${params}`);

			if ([400, 401, 403].includes(response?.status)) {
				clearStorageAndRedirect('/');
			}

			return response.data;
		} catch (error) {
			return error;
		}
	},

	async getSingleJob(id = '') {
		try {
			const response = await api.get(`jobs/${id}`);

			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async getSingleJobApplicantList(queryKey, jobId) {
		// eslint-disable-next-line no-unused-vars
		const [key = '', compatibility = [], rating = []] = queryKey.queryKey;

		const params = new URLSearchParams();

		if (rating?.length > 0) {
			params?.set('rating', rating);
		}

		if (compatibility?.length > 0) {
			params?.set('compatability', compatibility);
		}

		try {
			const response = await api.get(`/application/job/${jobId}?${params}`);

			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async getOpenSingleJobById(id = '') {
		try {
			const response = await axios.get(`${baseURL}/api/en/v1/open/job/${id}`);

			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async getOpenSingleJobByCompany({ company, job }) {
		try {
			const response = await axios.get(`${baseURL}/api/en/v1/open/job/${company}/${job}`);
			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async getAllOpenJobsByCompanyId(id = '') {
		try {
			const response = await axios.get(`${baseURL}/api/en/v1/open/jobs/${id}`);

			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async getAllOpenJobsByEncryptedCompanyId(id = '') {
		try {
			const response = await axios.get(`${baseURL}/api/en/v1/open/jobs/${id}`);

			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async deleteJob(id = '') {
		try {
			const response = await api.delete(`/jobs/${id}`);
			if (response?.data?.success) {
				toast.success('Successfully deleted!');
			}
			return response?.data;
		} catch (error) {
			return error;
		}
	},
	async putCloseJob(id = '') {
		try {
			const response = await api.put(`/jobs/close/${id}`);

			return response?.data;
		} catch (error) {
			return error;
		}
	},
};

export const {
	getJobs,
	getSingleJob,
	deleteJob,
	getOpenSingleJobById,
	getOpenSingleJobByCompany,
	getAllOpenJobsByCompanyId,
	getAllOpenJobsByEncryptedCompanyId,
	putCloseJob,
	getSingleJobApplicantList,
} = jobApis;
export default jobApis;
