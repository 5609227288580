import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LoginFooter = () => {
	const { t } = useTranslation();

	return (
		<div className="mt-6 flex w-full flex-col items-center justify-center exs:flex-row sm:w-[410px]">
			<h6 className=" text-center font-Montserrat text-base font-semibold leading-[21.94px] text-[#5F6D83]">
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				{t(`Don't have an account?`)}
			</h6>
			<Link to="/signup">
				<h6 className="px-2 text-center font-Montserrat text-base font-semibold leading-[21.94px] text-[#1098FF] underline">
					{t('Sign Up For Free')}
				</h6>
			</Link>
		</div>
	);
};

export default LoginFooter;
