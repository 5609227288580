import axios from 'axios';
import baseURL from './baseURL';
import { accessTokenHandler } from './requestInterceptors';
import { refreshTokenHandler } from './responseInterceptors';

const api = axios.create({
	baseURL: `${baseURL}/api/en/v1`,
	timeout: 10000,
});

api.interceptors.request.use(
	(config) => accessTokenHandler(config),
	(error) => Promise.reject(error)
);

api.interceptors.response.use(undefined, (error) => {
	return refreshTokenHandler(error);
});

export default api;
