import { getCookieItem, setCookieItem } from './cookies';

export const setUserRole = (role) => {
	localStorage.setItem('role', JSON.stringify(role));
	setCookieItem('role', role, { path: '/' });
};

export const getUserRole = () => {
	const roleFromLocalStorage = localStorage.getItem('role');
	const roleFromCookieStorage = getCookieItem('role');

	if (roleFromCookieStorage) {
		return roleFromCookieStorage;
	}

	if (roleFromLocalStorage) {
		return roleFromLocalStorage;
	}

	return null;
};
