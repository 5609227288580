const NabCompanySVG = ({ width = '22', height = '30', className = '', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox={`0 0 ${width} ${height}`}
			style={style}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0.332031 0L10.92 9.99973H21.508V0H0.332031Z" fill="#88DDFF" />
			<path d="M10.92 9.99902H0.332031V19.9988H21.508L10.92 9.99902Z" fill="#00AAFF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.625 20.0005H16.213V30.0002L5.625 20.0005Z"
				fill="#0055FF"
			/>
		</svg>
	);
};

export default NabCompanySVG;
