const LText = ({ children = null, className = '', style = {}, placeholder }) => {
	return (
		<div
			style={style}
			className={`text-center font-Montserrat font-medium leading-[30px] text-gray-400 md:text-[20px] ${className}`}
			placeholder={placeholder}
		>
			{children}
		</div>
	);
};

export default LText;
