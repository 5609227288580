import toast from 'react-hot-toast';

import api from './config/axiosInstance';
import { CREATED, OK } from './config/statuscode';

const companyAPIs = {
	async postImageFileAPI(file) {
		try {
			const response = await api.post('/uploads', file, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if ([OK, CREATED].includes(response?.status)) {
				toast.success('Save Your Change to Update Image.');
			}
			return response.data;
		} catch (error) {
			return error;
		}
	},

	async putCompanyInfoUpdate(updateData = {}) {
		const response = await api.put(`/company/profile`, updateData?.companyMutateData);

		if ([OK, CREATED].includes(response?.status)) {
			toast.success('Successfully Updated !');
		}
		return response.data;
	},

	async getCompanyProfileAPI() {
		try {
			const response = await api.get(`/company/profile`);
			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async getCountryListAPI() {
		try {
			const response = await fetch(`https://restcountries.com/v3.1/all`);
			const data = await response.json();
			const countries = data?.map((country) => country.name.common);
			return countries.sort((a, b) => a.localeCompare(b));
		} catch (error) {
			return error;
		}
	},

	async postChangePassword(data) {
		try {
			const response = await api.post('/users/reset-password', data);
			return response.data;
		} catch (error) {
			return error;
		}
	},
};

export const {
	postImageFileAPI,
	putCompanyInfoUpdate,
	getCompanyProfileAPI,
	getCountryListAPI,
	postChangePassword,
} = companyAPIs;

export default companyAPIs;
