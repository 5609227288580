const LongRightArrow = () => {
	return (
		<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.46154 12L13 6.5M13 6.5L7.46154 1M13 6.5L0.999999 6.5"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default LongRightArrow;
