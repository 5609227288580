/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useReducer } from 'react';
import { initialState, reducer } from '../reducers/userRoleReducer';

export const UserRoleContext = createContext();

export const UserRoleProvider = ({ children = null }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const value = { state, dispatch };

	return <UserRoleContext.Provider value={value}>{children}</UserRoleContext.Provider>;
};
