const WriteSVG = ({ width = '16', height = '17', className = 'text-[#00FFAB]', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			className={className}
			style={style}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.3332 4.5L5.99984 11.8333L2.6665 8.5"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default WriteSVG;
