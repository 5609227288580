const ArrowDown = ({ width = '24', height = '24', className = 'text-[#02060C]', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={style}
		>
			<path
				d="M5.0007 10.1698C5.00019 10.3192 5.03316 10.4668 5.09719 10.6018C5.16122 10.7368 5.25468 10.8557 5.3707 10.9498L11.3707 15.7798C11.5496 15.9269 11.7741 16.0073 12.0057 16.0073C12.2373 16.0073 12.4618 15.9269 12.6407 15.7798L18.6407 10.7798C18.8449 10.6101 18.9733 10.3662 18.9977 10.1018C19.0221 9.83733 18.9404 9.57405 18.7707 9.36984C18.601 9.16562 18.3571 9.0372 18.0926 9.01282C17.8282 8.98844 17.5649 9.0701 17.3607 9.23984L12.0007 13.7098L6.6407 9.38984C6.49391 9.26755 6.31517 9.18988 6.12562 9.166C5.93608 9.14212 5.74365 9.17303 5.57112 9.25509C5.3986 9.33715 5.25319 9.46691 5.15209 9.62902C5.051 9.79113 4.99847 9.9788 5.0007 10.1698Z"
				fill="#02060C"
			/>
		</svg>
	);
};

export default ArrowDown;
