import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	fallbackLng: 'en',
	lng: 'en',
	resources: {
		en: {
			// eslint-disable-next-line global-require
			translations: require('./locales/en/translations.json'),
		},
		bn: {
			// eslint-disable-next-line global-require
			translations: require('./locales/bn/translations.json'),
		},
	},
	ns: ['translations'],
	defaultNS: ['translations'],
});

i18n.language = ['en', 'bn'];
i18n.init();

export { i18n };
export default i18n;
