const ManageTeamLogo = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.5 10.5537C10.2546 10.5537 12.4626 8.31705 12.4626 5.52684C12.4626 2.73663 10.2546 0.5 7.5 0.5C4.74543 0.5 2.53737 2.73663 2.53737 5.52684C2.53737 8.31705 4.74543 10.5537 7.5 10.5537ZM7.5 13.0152C3.45422 13.0152 0 13.6621 0 16.2464C0 18.8298 3.4332 19.5 7.5 19.5C11.5448 19.5 15 18.8531 15 16.2687C15 13.6844 11.5668 13.0152 7.5 13.0152ZM17.8979 7.58786H19.101C19.5962 7.58786 20 7.99731 20 8.49948C20 9.00165 19.5962 9.4111 19.101 9.4111H17.8979V10.5884C17.8979 11.0906 17.4952 11.5 16.999 11.5C16.5038 11.5 16.1 11.0906 16.1 10.5884V9.4111H14.899C14.4027 9.4111 14 9.00165 14 8.49948C14 7.99731 14.4027 7.58786 14.899 7.58786H16.1V6.41162C16.1 5.90945 16.5038 5.5 16.999 5.5C17.4952 5.5 17.8979 5.90945 17.8979 6.41162V7.58786Z"
				fill="#1098FF"
			/>
		</svg>
	);
};

export default ManageTeamLogo;
