import { Field, useField } from 'formik';
import { useTranslation } from 'react-i18next';

const CustomInputField = ({ name, type, className, placeholder, ...inputProps }) => {
	const [field, meta] = useField(name);
	const { t } = useTranslation();

	return (
		<div>
			<Field
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...field}
				name={name}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...inputProps}
				className={className}
				type={type}
				placeholder={placeholder}
			/>
			{meta?.touched && meta?.error && (
				<div className="mb-1 text-start text-sm text-red">{t(meta.error)}</div>
			)}
		</div>
	);
};

export default CustomInputField;
