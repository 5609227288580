const CrossSvg = ({ width = '40', height = '40', className = '', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			className={className}
			style={style}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.6665 31.3335L31.3334 8.66659"
				// stroke="#02060C"
				stroke="CurrentColor"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
			<path
				d="M31.3334 31.3334L8.6665 8.6665"
				// stroke="#02060C"
				stroke="CurrentColor"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CrossSvg;
