import ChangePassSVG from '../assets/svgs/ChangePassSVG';
import EditSVG from '../assets/svgs/EditSVG';
import GlobeSVG from '../assets/svgs/GlobeSVG';
import ManageTeamLogo from '../assets/svgs/ManageTeamLogo';
import settingsSVG from '../assets/svgs/settingsSVG';
import { ADMIN, ASSISTANT, MANAGER } from '../constants/roles';

export const navbarMenuItems = [
	{
		id: 'a9277d8d-4c6e-4a5b-8512-35e7a031ec63',
		logo: GlobeSVG,
		text: 'Language',
		language: 'english',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
	},
	{
		id: 'a9277d8d-4c6e-4a5b-8512-35e7a031ec64',
		logo: ChangePassSVG,
		text: 'Change Password',
		path: '/profile/change-password',
		exact: true,
		roles: [ADMIN],
	},
	{
		id: 'a9277d8d-4c6e-4a5b-8512-35e7a031ec65',
		logo: ManageTeamLogo,
		text: 'Invite Your Team',
		path: '/manage-team',
		exact: true,
		roles: [ADMIN],
	},
	{
		id: 'a9277d8d-4c6e-4a5b-8512-35e7a031ec66',
		logo: EditSVG,
		text: 'Edit Profile',
		path: '/profile/edit',
		exact: true,
		roles: [ADMIN],
	},
	{
		id: 'a9277d8d-4c6e-4a5b-8512-35e7a031ec67',
		logo: settingsSVG,
		text: 'Integration',
		path: '/settings',
		exact: true,
		roles: [ADMIN, MANAGER],
	},
];
